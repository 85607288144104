.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#content {
  width: 100%;
  height: 100%;
  margin-left: 230px;
  z-index: 1;
}

#sidebar {
  display: flex;
  flex-direction: column;
  min-width: 230px;
  max-width: 230px;
  height: 100vh;
  background: #ffffff;
  color: #425466;
  transition: all 0.3s;
  z-index: 2;
  position: fixed;
  border-right: 1px solid #edf2f7;
}

#sidebar.active {
  margin-left: -230px;
}

#sidebar a,
#sidebar a:hover,
#sidebar a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar .sidebar-header {
  flex: 1;
  min-height: 120px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidebar .sidebar-body {
  flex: 8;
  padding-top: 20px;
}

#sidebar ul.components {
  margin: 0px;
}

#sidebar ul {
  list-style-type: none;
}

#sidebar ul li {
  display: flex;
  flex-direction: row;
}

#sidebar ul li a {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  display: block;
  justify-content: center;
  align-items: center;
}

#sidebar ul li a:hover {
  color: #4c6fff;
  background: #fff;
}

#sidebar .sidebar-footer {
  flex: 1.5;
  padding-top: 20px;
  background: #ffffff;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -230px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  #sidebar-collapse {
    display: none;
  }
}
