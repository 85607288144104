.container {
  padding: 30px;
}

.big-tile {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  color: black;
  height: 100%;
  padding: 20px;
  width: 100%;
}

.tile-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tile-header-image {
  flex: 5;
}

.tile-header-title {
  flex: 5;
  font-weight: bold;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
}

.big-tile-body {
  width: 100%;
  height: 100%;
}

.btn-primary {
  background-color: #4c6fff;
  border-color: #4c6fff;
}
.btn-primary:hover {
  background-color: #728efd;
  border-color: #728efd;
}
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #4c6fff;
  border-color: #4c6fff;
  box-shadow: none;
}

.btn-secondary {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #16192c;
}
.btn-secondary:hover {
  background-color: #e9e7e7;
  border: 2px solid #e9e7e7;
  color: #16192c;
}
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  background-color: #e9e7e7;
  border: 2px solid #e9e7e7;
  color: #16192c;
  box-shadow: none;
}

.btn-info {
  background-color: #a6b7d4;
  border: 2px solid #a6b7d4;
  color: #ffffff;
}
.btn-info:hover {
  background-color: #c4cad4;
  border: 2px solid #c4cad4;
  color: #ffffff;
}
.btn-info:active,
.btn-info:visited,
.btn-info:focus {
  background-color: #c4cad4;
  border: 2px solid #c4cad4;
  color: #ffffff;
  box-shadow: none;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  background-color: #e77c87;
  border-color: #e77c87;
}
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus {
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: none;
}

.button-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.custom-button-image {
  margin-right: 10px;
}

.header-bar {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
}

.login-card {
  background-color: #ffffff;
  border-radius: 10px;
  border-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.welcome-message {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}

.import-box {
  height: 50px;
  width: 50px;
  background-color: #e4ecf7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.import-box-number {
  font-size: 20px;
  font-weight: bold;
  color: #a6b7d4;
}

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 15px;
}

.form-label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  color: #425466;
}

.toggle-form-label {
  font-size: 14px;
  font-weight: normal;
  color: #718096;
}

.form-input {
  color: #000000;
  font-size: 14px;
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  padding: 20px 16px;
}

.form-input::placeholder {
  color: #b5b5bd;
}

.form-input:focus {
  border: 0px solid #4c6fff;
  box-shadow: 0 0 3px #4c6fff;
  outline-offset: 0px;
  outline: none;
}

.form-check-input {
  background-color: #ffffff;
  border-color: #a7a7a7;
}
.form-check-input:hover {
  background-color: #f1f1f1;
  border-color: #a7a7a7;
}
.form-check-input:checked {
  background-color: #66cb9f;
  border-color: #ffffff;
}
.form-check-input:checked:hover {
  background-color: #42b181;
  border-color: #42b181;
}
.form-check-input:focus {
  border-color: none;
  box-shadow: none;
}

.navbar-collapse {
  border-top: #718096;
}

.navbar {
  border-top: 1px solid #edf2f7;
  padding-left: 30px;
}

.nav-link {
  color: #718096;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
}

.nav-link:hover {
  color: #1a202c;
}

.nav-link:focus {
  color: #1a202c;
}
