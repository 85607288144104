.provisioning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: auto;
}

.provisioning-box-ext {
  flex: 8;
}

.provisioning-box {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.provisioning-box-content {
  padding: 30px;
}

.provisioning-icon {
  flex: 2;
  display: flex;
  justify-content: center;
}

.provisioning-title {
  text-align: center;
  color: #27272e;
  margin-top: 20px;
}

.provisioning-main-title {
  font-size: 28px;
  font-weight: bold;
}

.provisioning-sub-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
}

.provisioning-form {
  margin-top: 20px;
}

.provisioning-form-label {
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  color: #425466;
}

.provisioning-form-input {
  color: #425466;
  font-size: 14px;
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  padding: 20px;
}

.provisioning-form-input::placeholder {
  color: #b5b5bd;
}

.provisioning-form-input:focus {
  border: 0px solid #4c6fff;
  box-shadow: 0 0 3px #4c6fff;
  outline-offset: 0px;
  outline: none;
}

.provisioning-form-input-error {
  color: #ff0000;
  font-size: 11px;
}

.provisioning-form-label-switch {
  color: #718096;
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
}

.provisoning-button-box {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.provisoning-button-single {
  width: 100%;
  font-weight: bold;
}

.provisoning-button {
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  font-weight: bold;
}

.progress-bar-box {
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.progress-bar-line {
  background-color: #4c6fff;
  margin: 5px;
  border-radius: 2.5px;
  height: 7px;
  width: 90px;
}
