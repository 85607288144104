/* Cursor */
.table_cursor {
  cursor: grab;
}

/* Table layout */
.table_viewer {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
}

.table_grid {
  flex: 8;
  border: 2px solid #d6d3d3;
  border-radius: 4px;
}

.table_tools {
  flex: 2;
  border: 2px solid #d6d3d3;
  margin-right: 10px;
  border-radius: 4px;
}

.table_seat_cursor {
  cursor: pointer;
}

.table_name_text {
  pointer-events: none;
}

.table_seat_text {
  pointer-events: none;
}

.table_seat_guest_name_text {
  pointer-events: none;
}

.tool_palet_element {
  flex-direction: column;
  padding: 10px;
  height: 100%;
}
