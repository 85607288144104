.app {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-style: normal;
}

#root,
.app {
  height: 100%;
  width: 100%;
  background-color: #f7fafc;
}

body {
  margin: 0;
  height: 100vh;
}

/* main {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
} */
