.table-header-cell {
  color: #8492a6;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.06em;
  border: 1px solid #edf2f7;
}

.table-body-cell {
  color: #425466;
  font-size: 14px;
  border: 1px solid #edf2f7;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #425466;
}

.btn-light {
  background-color: #ffffff;
  border: 2px solid #edf2f7;
  color: #425466;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
}
.btn-light:hover {
  background-color: #e9e7e7;
  border-color: #e9e7e7;
}
.btn-light:active,
.btn-light:visited,
.btn-light:focus {
  background-color: #edf2f7;
  outline: none;
  box-shadow: none;
}

.pagination-select {
  background-color: #ffffff;
  border-color: #edf2f7;
  border-radius: 6px;
  border: 1px solid #edf2f7;
  cursor: pointer;
  padding: 3px;
  margin-right: 20px;
}

.pagination-select:hover {
  border: 1px solid #a4b5c5;
}

.pagination-select:focus {
  outline: none;
}

.pagination-element-filtered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
